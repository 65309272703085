import { useEffect, useState } from 'react'
import {
  isLoggedIn,
  verifyTokenExpiration,
  logout,
  getUser,
  refreshUserData
} from './services/auth'
import './App.css'
import Header from './components/header'
import Login from './pages/login'
import Create from './pages/create'
import Collections from './pages/collections'
import Faq from './pages/faq'
import Gallery from './pages/gallery'
// import Studio from './pages/studio'
import Footer from './components/footer'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-tippy/dist/tippy.css'
import actions from './redux/actions'
import Maintenance from './pages/maintenance'
import Payment from './pages/payment'
import Contact from './pages/contact'
import Terms from './pages/terms'
import Download from './pages/downloads'
import PDFViewer from './pages/pdf-viewer'

// Enviroment variable to turn on the maintenance mode.
const UNDER_MAINTENANCE = process.env.REACT_APP_MAINTENANCE
console.log('UNDER_MAINTENANCE', process.env.REACT_APP_MAINTENANCE)
const { useDispatch, resetCollections, resetCreateFormValues, updateUsername } =
  actions

// Starter routes for signed users.
const defaultRoute = '/gallery'
const allowRoutes = ['/terms', '/view']

const App = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const Logout = () => {
      // reset old redux  state from store
      dispatch(resetCollections())
      dispatch(resetCreateFormValues())
      logout(navigate('/login'))
    }
    // Verify Already Logged
    const AuthVerification = async () => {
      if (isLoggedIn()) {
        refreshUserData()
        dispatch(updateUsername(getUser().username))

        let match = false

        // Validate starting route .
        // if the provided route is not allowed them redirect to default starter route ( /gallery )
        for (let i = 0; i < allowRoutes.length; i++) {
          const route = allowRoutes[i]
          match = location.pathname.match(route)
          if (match) break
        }

        if (!match) {
          navigate(defaultRoute)
        }
      } else {
        navigate('/login')
      }
    }
    // Verify Token expiration time
    const tokenVerify = async () => {
      if (isLoggedIn()) {
        const hourToExpire = await verifyTokenExpiration()
        if (hourToExpire < 1) {
          Logout()
        }
      }
    }

    if (!props.appStarted) {
      AuthVerification()
      tokenVerify()
      props.setOnAppStarted(true)
    }
  }, [navigate, dispatch, location.pathname, props])

  return (
    <>
      {/** Ignore header on path /loing */}
      {location.pathname !== '/login' && location.pathname !== '/' && (
        <Header />
      )}

      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/create' element={<Create />} />
        <Route path='/collections' element={<Collections />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/gallery' element={<Gallery />} />
        {/* <Route path='/studio' element={<Studio />} /> */}
        <Route path='/payment' element={<Payment />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/downloads' element={<Download />} />
        <Route path='/view/:id' element={<PDFViewer />} />

      </Routes>
      {location.pathname !== '/login' && location.pathname !== '/' && (
        <Footer />
      )}
    </>
  )
}

const AppWrapper = () => {
  const [appStarted, setOnAppStarted] = useState(false)

  return (
    <Router>
      {(!UNDER_MAINTENANCE || UNDER_MAINTENANCE === 'false') && (
        <>
          <App appStarted={appStarted} setOnAppStarted={setOnAppStarted} />
          <ToastContainer
            position='top-right'
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            rtl={false}
            theme='colored'
          />
        </>
      )}

      {UNDER_MAINTENANCE === 'true' && <Maintenance />}
    </Router>
  )
}

export default AppWrapper
