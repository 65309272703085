import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getMetadataFile, getMetadataByToken } from '../services/payload'
import { Document, Page, pdfjs } from 'react-pdf'
import { toast } from 'react-toastify'
import pdfStyles from './styles/pdfViewer.module.scss'

// Assets
import forwardIcon from '../components/assets/forward.svg'
import backwardIcon from '../components/assets/backward.svg'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// workerSrc config
pdfjs.GlobalWorkerOptions.workerSrc = pdfjs.GlobalWorkerOptions.workerSrc =
  pdfjs.getDocument.workerSrc ||
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
// `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`

export default function PDFViewer (props) {
  const [inFetch, setInFetch] = useState(true)
  const [numPages, setNumPages] = useState()
  const [selectedPag, setSelectedPag] = useState(1)

  const [url, setUrl] = useState()
  const params = useParams()

  const onDocumentLoadSuccess = (info) => {
    const { _pdfInfo } = info
    setNumPages(_pdfInfo.numPages)
  }

  useEffect(() => {
    const getFileData = async () => {
      try {
        const { id } = params
        const tokenId = id
        const metadataRes = await getMetadataByToken(tokenId)

        const metadata = metadataRes.metadata
        const metadataId = metadata._id
        const data = await getMetadataFile(metadataId)
        if (!data) {
          throw new Error('Data not found!')
        }

        const urlCreator = window.URL || window.webkitURL
        const href = urlCreator.createObjectURL(data)
        setUrl(href)
        setInFetch(false)
      } catch (error) {
        console.warn(error)
        setInFetch(false)
        toast.error('Error! : ' + error.message)
      }
    }

    getFileData()
  }, [params])

  // Go pdf to next page
  const forward = useCallback(() => {
    setSelectedPag(selectedPag + 1)
  }, [selectedPag])
  // Go back pdf to last page
  const back = useCallback(() => {
    setSelectedPag(selectedPag - 1)
  }, [selectedPag])

  // Handle key down event
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'ArrowLeft' && selectedPag - 1 > 0) back()
      if (e.key === 'ArrowRight' && selectedPag + 1 <= numPages) forward()
    },
    [numPages, selectedPag, back, forward]
  )

  useEffect(() => {
    // Add key down event for this component.
    document.addEventListener('keydown', handleKeyDown)

    // Clear event on unmount this component
    return function cleanup () {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <>
      {url && !inFetch && (
        <div id='pdf-wrapper'>
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={selectedPag} />
          </Document>

          <div className={pdfStyles.btnSection}>
            <button disabled={selectedPag - 1 <= 0} onClick={back}>
              <img
                className={pdfStyles.icon}
                src={backwardIcon}
                alt='backward btn'
              />
            </button>
            Page {selectedPag} of {numPages}
            <button disabled={selectedPag + 1 > numPages} onClick={forward}>
              <img
                className={pdfStyles.icon}
                src={forwardIcon}
                alt='forward btn'
              />
            </button>
          </div>
        </div>
      )}

      {/** show spinner in fetch */}
      {inFetch && (
        <PropagateLoader
          color='#ffffff'
          loading={inFetch}
          size={5}
          cssOverride={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em',
            alignItems: 'center',
            minHeight: '60vh'
          }}
          speedMultiplier={1}
        />
      )}
    </>
  )
}
