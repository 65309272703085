import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSackDollar } from '@fortawesome/free-solid-svg-icons'
import './styles/accordion.scss'

const AccordionFaq = () => (
  <Accordion.Root
    className='AccordionRoot'
    type='single'
    defaultValue='item-1'
    collapsible
  >
    <Accordion.Item className='AccordionItem' value='item-1'>
      <AccordionTrigger>What is an NFT?</AccordionTrigger>
      <AccordionContent>
        An NFT, or non-fungible token, is a type of digital asset that
        represents ownership of a unique item, such as a piece of artwork or
        collectible. Unlike traditional cryptocurrencies, which are fungible and
        interchangeable, NFTs cannot be replaced by another identical item.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-2'>
      <AccordionTrigger>What is a TokenTiger NFT?</AccordionTrigger>
      <AccordionContent>
        TokenTiger produces NFTs using the SLP Type 1 mutable data open source
        protocol, referenced to the Bitcoin Cash (BCH) blockchain. By minting an
        SLP type 1 token with "decimals" set to zero, the token is
        non-divisible, and operates as a non-fungible token.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-3'>
      <AccordionTrigger>What is a Payload?</AccordionTrigger>
      <AccordionContent>
        A payload is data attached to an NFT, which can include images, PDF documents,
        or ZIP archives. Payloads can be configured as either public or private. Private
        payloads are secured locally, while public payloads are stored and distributed
        through the IPFS (InterPlanetary File System) network.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-4'>
      <AccordionTrigger>
        How can I transfer or sell TokenTiger NFT?
      </AccordionTrigger>
      <AccordionContent>
        TokenTiger is working on a number of mechanisms to enable you to sell or
        transfer your NFTs. Meanwhile, you can transfer an NFT to an open
        source, non-custodial wallet found at
        <a
          href='https://bchn-wallet.fullstack.cash/'
          target='_blank'
          rel='noopener noreferrer'
          className='walletLink'
        >
          {' '}
          wallet.fullstack.cash{' '}
        </a>{' '}
        Click the toggle below the QR code to obtain a wallet specific to NFTs.
        Click the "send" icon on the NFT card and enter the address from your
        fullstack.cash wallet. (Don't lose your 12-word mnemonic! Non-custodial
        wallets cannot be restored without this information!)
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-5'>
      <AccordionTrigger>How much does it cost to make an NFT?</AccordionTrigger>
      <AccordionContent>
        Making an NFT costs 1 credit. You may get up to 3 free credits per day,
        up to 10 total credits, by clicking the{' '}
        <FontAwesomeIcon icon={faSackDollar} /> icon. In the future, credits
        will be available for sale.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-6'>
      <AccordionTrigger>What is a "Collection"?</AccordionTrigger>
      <AccordionContent>
        An NFT is typically released as part of a named Collection. TokenTiger
        associates a collection name with a particular custodial wallet
        associated with a user account.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-7'>
      <AccordionTrigger>What is a "Sharable Collection"?</AccordionTrigger>
      <AccordionContent>
        A Sharable Collection enables collaborative access to a curated set of NFTs.
        When a collection is shared, authorized users can view all NFTs within that collection
        through an access link. For NFTs with public payloads, the complete original content
        is accessible, while NFTs with private payloads maintain privacy by displaying only their
        associated token icon.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-8'>
      <AccordionTrigger>What is the Bitcoin Cash blockchain?</AccordionTrigger>
      <AccordionContent>
        Bitcoin Cash (BCH) is a cryptocurrency that was created as a fork of the
        original Bitcoin blockchain in 2017. It uses the same basic technology
        as Bitcoin, but with increased block size limits, which allows for
        faster and cheaper transactions.
      </AccordionContent>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-9'>
      <AccordionTrigger>
        How are NFTs stored on the Bitcoin Cash blockchain?
      </AccordionTrigger>
      <Accordion.Content className='AccordionContent'>
        <div className='AccordionContentText'>
          NFTs can be stored on the Bitcoin Cash blockchain using a process
          called "tokenization." This involves creating a unique digital token,
          or NFT, that represents ownership of the item and then storing that
          token on the blockchain. This allows for the item to be easily bought,
          sold, and transferred while maintaining its uniqueness and provenance.
        </div>
      </Accordion.Content>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-10'>
      <AccordionTrigger>
        How does the ownership of an NFT work?
      </AccordionTrigger>
      <Accordion.Content className='AccordionContent'>
        <div className='AccordionContentText'>
          Ownership of an NFT is recorded on the blockchain, the digital ledger
          that serves as the backbone of the cryptocurrency. When someone buys
          an NFT, they receive a digital token that represents ownership of the
          item. The token is then recorded on the blockchain, and the ownership
          can be tracked and transferred as needed.
        </div>
      </Accordion.Content>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-11'>
      <AccordionTrigger>How do I know if an NFT is authentic?</AccordionTrigger>
      <Accordion.Content className='AccordionContent'>
        <div className='AccordionContentText'>
          NFTs can be authenticated by checking the blockchain for the unique
          digital token that represents the item. Additionally, some platforms
          or marketplaces have verification processes in place to ensure that
          the item being sold is legitimate.
        </div>
      </Accordion.Content>
    </Accordion.Item>

    <Accordion.Item className='AccordionItem' value='item-12'>
      <AccordionTrigger>Can I resell an NFT?</AccordionTrigger>
      <Accordion.Content className='AccordionContent'>
        <div className='AccordionContentText'>
          Yes, NFTs can be bought and sold on the open market, just like
          traditional collectibles. The ownership of an NFT is recorded on the
          blockchain, so it can be easily transferred to a new owner. However,
          the value of an NFT may fluctuate depending on demand.
        </div>
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Root>
)

const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className='AccordionHeader'>
      <Accordion.Trigger
        className={classNames('AccordionTrigger', className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <ChevronDownIcon className='AccordionChevron' aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  )
)

const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames('AccordionContent', className)}
      {...props}
      ref={forwardedRef}
    >
      <div className='AccordionContentText'>{children}</div>
    </Accordion.Content>
  )
)

export default AccordionFaq
